import React, { useEffect, useState, useRef } from "react";
import SEO from "../common/SEO";
import "../css/style.css";
import "../css/example.css";
import Layout from "../common/Layout";
import { strapi_base_url } from "../utils/base_url";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// const HomeImageSkeleton = () => (
//   <div>
//     <Skeleton height={500} />
//   </div>
// );

function HomeDefault() {
  const eventsRef = useRef(null);
  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    fetch(`${strapi_base_url}/api/home?populate=*`)
      .then((response) => response.json())
      .then((responseData) => {
        setHomeData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Check if the URL contains #events and scroll if it does
    if (window.location.hash === "#events" && eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const content =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.content;
  const bannerImage =
    homeData &&
    homeData.data &&
    homeData.data.attributes &&
    homeData.data.attributes.bannerImage &&
    homeData.data.attributes.bannerImage.data[0] &&
    homeData.data.attributes.bannerImage.data[0].attributes &&
    homeData.data.attributes.bannerImage.data[0].attributes.url;

  return (
    <div>
      <SEO
        title={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaTitle
        }
        description={
          homeData &&
          homeData.data &&
          homeData.data.attributes &&
          homeData.data.attributes.metaDescription
        }
      />
      <Layout>
        <div
          style={{
            position: "relative",
          }}
          className="bg_image"
        >
          {loading && <div className="skeleton-loader"></div>}

          <img
            src={`${strapi_base_url}${bannerImage}`}
            alt="Hero"
            width={"100%"}
            height={"100%"}
            onLoad={handleImageLoad}
            style={
              loading ? { visibility: "hidden" } : { visibility: "visible" }
            }
          />
          {/* {loading ? (
                <HomeImageSkeleton />
              ) : (
                <img
            src={`${strapi_base_url}${bannerImage}`}
            style={{ width: "100%", height: "100%" }}
          />
        )} */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
              zIndex: 1, // Ensure overlay is above the background image
            }}
          />
          <div
            className="container"
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(0, -50%)",
              zIndex: "1",
              left: "0",
              right: "0",
            }}
          >
            <div className="bg_text">
              <h1 style={{ color: "white", width: "58%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerTitle}
              </h1>
              <p style={{ width: "55%" }}>
                {homeData &&
                  homeData.data &&
                  homeData.data.attributes &&
                  homeData.data.attributes.bannerText}
                <br />
              </p>
              {/* <a href="#">Learn More →</a> */}
            </div>
          </div>
        </div>
        <div className="">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        {/* <!-- Featured Insights & Events --> */}
        <div className="container events mt-5" id="events" ref={eventsRef}>
          <h1 className="text-center">Featured Insights &amp; Events</h1>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div className="card border-0 carousel_text">
                <div className="hovereffect">
                  <a href="https://www.asminternational.org/istfa-2024/">
                  <img
                    src="img/ISTFA-2024_Attending_FB-1536x807.png"
                    className="event-img"
                  />
                  </a>
                </div>
                <div className="card-body px-0">
                  <h3>
                    <a>ISTFA 2024</a>
                  </h3>
                  <p>
                    <a>
                      {" "}
                      October 28 - November 1, 2024 Town and Country <br />
                      San Diego, CA
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-12">
              <div className="card border-0 carousel_text">
                <div className="hovereffect">
                  <a href="https://ipfaieee.org/2025/"><img src="img/Logoonly-mobile.png" className="event-img" /> </a>
                </div>
                <div className="card-body px-0">
                  <h3>
                    <a>IPFA 2025</a>
                  </h3>
                  <p>
                    <a>
                      August 5 - 8, 2025 | Spice Convention Centre, <br />
                      Penang, Malaysia
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div className="card border-0 carousel_text">
                <div className="hovereffect">
                  <a href="https://www.esref2024.org/">
                  <img
                    src="https://admin.checkpointtechnologies.com/uploads/esref_557dac9e14.png"
                    className="event-img"
                  />
                  </a>
                </div>
                <div className="card-body px-0">
                  <h3>
                    <a>ESREF 2024</a>
                  </h3>
                  <p>
                    <a>
                      September 23 - 26, 2024 <br /> PARMA - Italy
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-12 ">
              <div className="card border-0 carousel_text">
                <div className="hovereffect">
                  <a href="https://www.gomactech.net/">
                  <img
                    src="https://admin.checkpointtechnologies.com/uploads/37_C761_BE_36_D4_4540_B8_F4_EB_7811_A3_B761_3ef72c4e7f.png"
                    className="event-img"
                  />
                  </a>
                </div>
                <div className="card-body px-0">
                  <h3>
                    <a>GOMAC 2025</a>
                  </h3>
                  <p>
                    <a>
                      March 17-20, 2025 Pasadena Convention Center <br />
                      Pasadena, CA, USA
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default HomeDefault;
